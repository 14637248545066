import $ from 'jquery';
import { findIndex } from 'lodash';
import { Component, Watch } from 'vue-property-decorator';
import AmsPlayer from '@/components/player/player_ams';
import Globals from '@/globals';
import Browser from '@/support/browser';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import Util from '@/support/utility';
import canAutoPlay from 'can-autoplay';
import StudioPageBase from '@/support/studioPageBase';
import { IVideo, IStudy, IShowSettings } from '@/interfaces';
import { VideoPlayerState } from '@/globals';
import { EventBus } from '@/support/eventBus';

// ReSharper disable InconsistentNaming
const DefaultFontSize = 10;
const DefaultWidth = 640;
const DefaultHeight = 360;
const RestoreSelectedStudy = false;
// ReSharper restore InconsistentNaming

@Component
export default class PlayerPageComponent extends StudioPageBase {
    options = {
        autoPlay: !Browser.getBoolParam('skipAutoPlay', false) && !Browser.getBoolParam('noAutoPlay', false),
        autoRefresh: !Browser.getBoolParam('noRefresh', false) && Browser.getBoolParam('autoRefresh', true),
        sizeOverwritten: Browser.getIntParam('playerWidth', 0) > 0 && Browser.getIntParam('playerHeight', 0) > 0,
        duration: Browser.getIntParam('duration', 0),
        height: Browser.getIntParam('playerHeight', DefaultHeight),
        hide: Browser.getBoolParam('hide', false),
        hideSideBar: Browser.getBoolParam("hideSideBar", false) || Browser.getBoolParam('playerOnly', false),
        liveVideoOnly: Browser.getBoolParam('liveVideoOnly', false),
        playerOnly: Browser.getBoolParam('playerOnly', false),
        mute: Browser.getBoolParam('mute', false),
        refreshInterval: Browser.getIntParam('refreshInterval', 5 * 60) * 1000,
        seekPos: Browser.getIntParam('seekPos', 0),
        stream: Browser.getParam('stream', null),
        title: Browser.getParamEscaped("title", null),
        width: Browser.getIntParam('playerWidth', DefaultWidth),
        showVideoLinks: Browser.getBoolParam('showVideoLinks', false),
        refreshTimeout: Browser.getIntParam("refreshTimeout", 300),
        multiPlayerView: Browser.getBoolParam('multiPlayerView', false),
        fakeLiveVideos: Browser.getIntParam('fakeLiveVideos', 0),
        fakeNewVideos: Browser.getIntParam('fakeNewVideos', 0),
        alwaysPlayNewVideo: Browser.getBoolParam('alwaysPlayNewVideo', false),
        fakeOwner: Browser.getBoolParam('fakeOwner', false),
        fakeAdmin: Browser.getBoolParam('fakeAdmin', false),
        settings: false,
        debugSettings: Browser.getParam('debugSettings', null),
    };

    canAutoPlay = false;
    loading = true;
    playList: SUR.PlayListModel = Globals.NewPlaylist;
    video: IVideo = null;
    wizardVideo: IVideo = null;
    shareUrl: string = null;
    adminVideo: IVideo = null;
    showMultiPlayers: boolean = false;
    resumed = false;

    live = {
        videos: <IVideo[]>[],
        playtests: [],
    };
    miniVideos: IVideo[] = [];

    playing = {
        autoPlay: false,
        state: VideoPlayerState.NONE,
        pendingStream: false, // this.options.stream if any
        video: <IVideo>null,
    };

    refresh = {
        lastRefreshCheck: new Date(),
        playIntervalMs: 20 * 1000, // playing check interval time
    };

    study = {
        selected: null,
        list: <IStudy[]>[]
    };

    timers = {
        refreshIntervalId: <any>0,
    };

    placeholder = {
        top: {
            line1: 'Live Session has not started yet,',
            line2: ' remain on the page until it begins automatically.',
        },
        middle: {
            line1: 'To watch previous session, select your study',
            line2: ' and click the video on the right.',
        },
        bottom: {
            line1: '*If you experience technical difficulties, please contact your user researcher.',
            line2: null,
        },
        error: {
            line1: null,
            line2: null,
        }
    };

    /* BEGIN COMPUTED */
    get show() {
        return !this.options.hide ||
            (this.playList.liveVideoCount > 0 || this.playList.videoCount > 0) ||
            this.live.videos.length > 0 || this.study.list.length > 0;
    }

    get showNoVideos() {
        return false;
    }

    get showSideBar() {
        return !this.options.playerOnly && !this.options.stream && !this.options.hideSideBar && (!this.options.liveVideoOnly || this.live.videos.length > 1 || this.live.playtests.length > 0);
    }

    get showVOD() {
        return !this.options.playerOnly && !this.options.liveVideoOnly && !this.options.stream && this.study.list.length > 0;
    }

    get showLiveSessions() {
        return !this.options.playerOnly && !this.options.stream && (this.study.list.length > 0 || this.live.videos.length > 0 || this.live.playtests.length > 0);
    }

    get liveButtonDisabled() {
        return this.live.videos.length == 0 || (this.playing.video && this.playing.video.isLive);
    }

    get liveButtonClass() {
        return this.live.videos.length ? 'btn-success' : 'btn-default';
    }

    get videoCaption() {
        if (!this.options.playerOnly && !this.options.sizeOverwritten && this.playing.video) {
            const livePrefix = this.playing.video.isLive ? ' Live ' : '';
            return 'Playing ' + livePrefix + 'Video - ' + this.playing.video.title;
        }
        return null;
    }

    get playableMiniVideos() {
        let playable = [];
        this.live.videos.forEach(video => {
            if (video.filter)
                return;

            playable.push(video);
        });
        return playable;
    }

    /* END COMPUTED */

    @Watch('study.selected', { immediate: true, deep: true })
    onSelectedStudyChanged(val: string, oldVal: string) {
        if (this.loading) return;

        if (this.study && this.study.selected && this.study.selected.name) {
            window.sessionStorage.setItem(this.studio.studio + '.selected.study', this.study.selected.name);
            //Debug.log('onSelectedStudyChanged', this.study.selected.name, this.study.selected.name.length);
        }
    }

    @Watch('studio', { immediate: true, deep: true })
    onStudioChanged(val: SUR.AzureStudioDto, oldVal: SUR.AzureStudioDto) {
        if (val && val.backgroundUrl) {
            this.updateBackgroundImage();
        }
    }

    created() {
        super.created('playerPage', true, true);
        Debug.setDebugModule('App', this);

        this.playing.autoPlay = this.options.autoPlay;

        if (this.options.fakeAdmin) {
            this.serverData.isAdmin = true;
            this.studio.isOwner = true;
        }

        if (this.options.fakeOwner)
            this.studio.isOwner = true;

        EventBus.Instance.$on(EventBus.VideosChanged, () => {
            this.refreshPage(true);
        });
    }

    mounted() {
        super.mounted();

        //console.log("playerPage mounted");

        this.resizePlayer(true);

        $(<any>window).resize(() => {
            this.resizePlayer();
        });

        this.playing.pendingStream = this.options.stream || false;

        this.timers.refreshIntervalId = setInterval(() => {
            //Debug.log("refresh from refreshInterval", this.options.refreshTimeout);
            this.refreshPage();
        }, this.options.refreshInterval);

        this.showInitialSettings();
    }

    beforeDestroy() {
        this.stopVideo(this.playing.video);

        this.miniVideos.forEach(video => {
            this.stopVideo(video);
        });
        this.miniVideos.splice(0, this.miniVideos.length);

        super.beforeDestroy();

        if (this.timers.refreshIntervalId) {
            clearInterval(this.timers.refreshIntervalId);
            this.timers.refreshIntervalId = 0;
        }
    }

    protected onLoaded(loaded: boolean) {
        super.onLoaded(loaded);

        if (!loaded || !this.studio.studioId) {
            PlayerUtil.redirectToRequestAccess(this.$router, this.alias);
            return;
        }

        document.title = this.studio.title + ' - ' + Globals.XboxResearch;

        this.updateBackgroundImage();
        this.initialLoad();
    }

    async initialLoad() {
        try {
            // get cached playList
            let playList = await this.getPlaylist(true);

            if (!playList) {
                Debug.error('initialLoad missing playList');
            }

            this.processPlaytestResponse(playList, false);
            this.checkCanAutoPlay();
        } catch (err) {
            Debug.error(err);
            Util.showError(err);
            PlayerUtil.redirectToError(this.$router);
        }
        finally {
            this.loading = false;
        }
    }

    resizePlayer(initialResize = false) {
        let current = {
            width: this.options.width,
            height: this.options.height,
        };

        if (this.options.width > window.innerWidth) {
            this.options.width = window.innerWidth;
            this.options.height = this.options.width * 9 / 16;
        } else if (window.innerWidth >= DefaultWidth) {
            if (this.options.width < DefaultWidth) {
                this.options.width = DefaultWidth;
                this.options.height = DefaultHeight;
            }
        }

        this.updateBackgroundImage();

        if (!initialResize && current.width == this.options.width)
            return;

        Browser.rescaleRootFontSize(this.options.width, DefaultWidth, DefaultFontSize);

        // NOTE: Need to bind via v-show in order to set element properties
        this.updateBackgroundImage();

        if (this.playing.video && this.playing.video.player) {
            this.playing.video.player.resizeVideo(this.options.width, this.options.height);
        }
    }

    updateBackgroundImage() {
        $('#placeholder-image').css('background', "url(" + this.studio.backgroundUrl + ")");
        $(".placeholder-image").css('background-size', this.options.width + 'px ' + this.options.height + 'px');
    }

    selectStudy() {
        if (RestoreSelectedStudy) {
            let selected = window.sessionStorage.getItem(this.studio.studio + '.selected.study');
            if (selected) {
                for (let idx = 0; idx < this.study.list.length; idx++) {
                    let study = this.study.list[idx];
                    if (study.name == selected) {
                        //console.log('selectStudy from storage', study.name);
                        this.study.selected = this.study.list[idx];
                        return;
                    }
                }
            }
        }

        if (this.study.list.length) {
            this.study.selected = this.study.list[0];
        } else {
            this.study.selected = null;
        }
    }

    onPlayLiveSessionClick() {
        if (this.live.videos.length)
            this.playVideo(this.live.videos[0], true);
    }

    /**
     * playVideo
     * @param video - video to play
     * @param userInvoked - true if user invoked
     * @param event - source event
     */
    async playVideo(video, userInvoked = false, event: any = {}) {
        event = event || {};
        userInvoked = userInvoked || false;

        this.stopVideo(video);
        if (this.playing.video)
            this.stopVideo(this.playing.video);
        this.updateMiniPlayers(video);

        Debug.log("playVideo", { userInvoked: userInvoked, options_autoPlay: this.options.autoPlay, canAutoPlay: this.canAutoPlay}, video, event);

        if (event && event.target) {
            video.busy = true;
        }

        let validation = await this.getStreamUrl(video);
        if (validation == null) {
            if ((event && event.target) || userInvoked) {
                video.busy = false;
            }

            if (userInvoked) {
                Util.showError('Failed to play video');
            }

            return null;
        }

        try {
            if (!validation.streamUrl) {
                this.showError("Failed to aquire stream");
                return null;
            }

            setTimeout(() => {
                if (event && event.target) {
                    video.busy = false;
                }
            }, 300);

            Debug.log("stream url", validation, video, event, event.target);
            video.streamUrl = validation.streamUrl;
            video.token = validation.token;
            video.testPlaybackUrl = 'https://ampdemo.azureedge.net/azuremediaplayer.html?url=' + encodeURI(video.streamUrl);
            if (video.token)
                video.testPlaybackUrl += '&aes=true&aestoken=' + encodeURIComponent(video.token);
            this.playing.video = video;
            this.playing.state = VideoPlayerState.NONE;
            this.playing.autoPlay = this.options.autoPlay;

            let canPlayAudio = (!userInvoked && this.canAutoPlay) || userInvoked;

            video.player = new AmsPlayer('sur-player', this.options.width, this.options.height);
            video.player.callbacks.onError = this.onError;
            video.player.callbacks.onEnded = this.onEnded;
            video.player.callbacks.onMute = this.onMute;
            video.player.initialSeekPos = this.options.seekPos;
            video.player.initialDuration = this.options.duration;
            video.player.mute = this.options.mute ? true : !canPlayAudio;
            video.autoMuted = !this.options.mute && !canPlayAudio;

            video.player.playVideo(video, this.options.autoPlay);

            this.loading = false;

            if (video.autoMuted) {
                Util.showToast('Video is muted.  Click on volume to unmute.');
            }

        } catch (e) {
            Debug.error("playVideo", video, e);
            this.showError(e);
        }

        return video;
    }

    unMutePlayingVideo() {
        if (!this.playing.video || !this.playing.video.player)
            return;

        this.playing.video.player.muteVideo(false);
        this.playing.video.autoMuted = false;
    }

    shareVideo(video) {
        let params = video.id;
        if (video.filter)
            params += '-' + video.filter;

        let url = window.location.origin + window.location.pathname + '?stream=' + encodeURIComponent(params);
        Debug.log('shareVideo', url);

        this.shareUrl = url;

        this.copyToClipboard(url, 'Copied share link to clipboard');
    }

    async createFilter(video: IVideo) {
        try {
            Debug.log('createFilter', video);

            if (video.player) {

                let endPos = Math.max(30, video.player.getPosition());
                if (endPos > 0) {
                    video.busy = true;
                    let startPos = Math.max(0, endPos - 30);

                    let model: SUR.CreateFilterDto = <any>PlayerUtil.videoToStreamApiDto(video);
                    model.startTimeSec = Math.floor(startPos);
                    model.endTimeSec = Math.floor(endPos);

                    Debug.log('createFilter', startPos, endPos, '( ' + (startPos / 60) + ' to ' + (endPos / 60) + ' sec )', model);
                    let result = await PlayerApi.createFilter(model);

                    this.refreshPage(true);

                    Util.showToast('Created clip');

                    return result;
                }
            }

            Util.showToast('Failed to create clip', true);
            return;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("error", message);
            Util.showToast(message, true);
            return null;
        } finally {
            video.busy = false;
        }
    }

    copyToClipboard(value: string, message: string = null) {
        Browser.copyToClipboard(value, message);
    }

    showVideoAdmin(video: IVideo) {
        this.showSettingsWizard('videoAdmin', video);
    }

    renameAsset(video, event: any = null) {
        this.showSettingsWizard('renameAsset', video);
    }

    deleteVideo(video, event: any = null) {
        this.showSettingsWizard('deleteVideo', video);
    }

    removeDeletedVideo(video: IVideo) {
        this.study.list.forEach(study => {
            let idx = study.videos.indexOf(video);
            if (idx != -1)
                study.videos.splice(idx, 1);
        });

    }

    async downloadVideo(video: IVideo, event: any = null) {
        if (video.disableDownload) {
            Debug.log('downloadVideo disabled', video.studio, video.id, event);
            return false;
        }

        Debug.log('downloadVideo starting', video.studio, video.id, event);
        video.busy = true;
        video.disableDownload = true;
        Util.showToast('Processing video. Download link will be provided when ready.<br/><br/>This can take 20 minutes or more.<br/><br/>Please wait.', false, '5000');

        let result = await this.recreateMP4(video);

        if (result.completed) {
            this.completeDownload(video, result.succeeded);
            return true;
        }

        let interval = setInterval(() => {
            this.waitForBackgroundJob(video, result, interval);
        }, 10000);

        return false;
    }

    async waitForBackgroundJob(video: IVideo, jobInfo: SUR.BackgroundJobDto, interval) {
        try {
            let result = await PlayerApi.checkBackgroundJob(jobInfo);
            if (result.completed) {
                Debug.log('waitForBackgroundJob', video, jobInfo);
            }

            if (result.completed) {
                clearInterval(interval);
                this.completeDownload(video, result.succeeded);
                return true;
            }

            // Still running
            video.downloadProgress = Math.round(result.progress);
            return false;
        } catch (err) {
            clearInterval(interval);
            this.completeDownload(video, false);

            let message = err.message || 'ERROR';
            Debug.error('waitForBackgroundJob', message, video);

            return false;
        }
    }

    completeDownload(video: IVideo, result: boolean) {
        video.busy = false;
        video.disableDownload = false;

        if (result)
            video.downloadUrl = "/Download/" + video.studio + "/" + video.id;
        else
            Util.showToast('Failed to create mp4 for ' + video.title, true);
    }

    async checkDownloadProgress(video: IVideo, jobInfo: SUR.BackgroundJobDto, interval) {
        try {
            let result = await PlayerApi.checkBackgroundJob(jobInfo);

            if (result.completed) {
                clearInterval(interval);
                this.completeDownload(video, result.succeeded);
                return true;
            }

            // Still running
            video.downloadProgress = Math.round(result.progress);
            return false;
        } catch (err) {
            clearInterval(interval);
            this.completeDownload(video, false);

            let message = err.message || 'ERROR';
            Debug.error('checkDownloadProgress', message, video);

            return false;
        }
    }

    showError(error: string, error2: string = null) {
        Debug.error("showError", error, error2);
        this.placeholder.error.line1 = error;
        this.placeholder.error.line2 = error2 || null;
    }

    clearError() {
        this.placeholder.error.line1 = null;
        this.placeholder.error.line2 = null;
    }

    // Player methods
    stopVideo(video: IVideo) {
        // stop on error
        try {
            if (this.playing.video == video)
                this.playing.video = null;

            if (video && video.player) {
                video.player.stopVideo();
                video.player = null;
            }
            this.clearError();
        } catch (e) {
            Debug.log("stop", e);
        }
    }

    // Events
    onEnded(video: IVideo) {
        if (this.playing.state == VideoPlayerState.ENDED) {
            Debug.log("onEnded", "already ended");
            return;
        }
        if (video == this.playing.video) {
            Debug.log("onEnded", this.playing.video.studio, this.playing.video.id, this.playing.video.id);
            this.playing.state = VideoPlayerState.ENDED;
            this.clearError();
            this.stopVideo(video);

            // refresh when video stopped at end
            if (this.options.autoRefresh)
                this.refreshPage(true);
        }

        this.miniVideos.forEach(miniVideo => {
            if (miniVideo == video) {
                this.stopVideo(video);
                video.dontPlay = true;
            }
        });
    }

    onError(video: IVideo, error: string, code: number, message: string) {
        Debug.error("onError", error, code, message, video.studio, video.id, video.id);
        this.playing.state = VideoPlayerState.ERROR;

        this.stopVideo(video);

        message = message || "Error could not play Stream";

        this.showError(message);

        if (video.isLive) {
            let idx = findIndex(this.live.videos, item => item.id == video.id);
            if (idx != -1) {
                this.live.videos.splice(idx, 1);
                Debug.error("Removed error video", video.study, video.id);
            }
        }

        // refresh when video stopped at end
        if (this.options.autoRefresh)
            this.refreshPage(true);
    }

    onMute(video: IVideo, mute: boolean) {
        if (this.playing.video == video)
            this.options.mute = mute;
    }

    clearAllTimers() {
        try {
            if (this.timers.refreshIntervalId) {
                try {
                    clearInterval(this.timers.refreshIntervalId);
                } catch (err) {
                    //ignored
                }
                this.timers.refreshIntervalId = null;
            }
        } catch (err) {
            // ignored
        }
    }

    async refreshPage(force: boolean = false, autoPlayLiveVideos: boolean = false) {
        const now = +new Date();
        force = force || false;
        if (!force) {

            const timeDiff = Math.abs(now - +this.refresh.lastRefreshCheck);
            if (timeDiff < this.options.refreshTimeout) {
                Debug.log("refreshPage refresh abort to early", this.options.refreshTimeout / (60 * 1000), now, this.refresh.lastRefreshCheck, "state", this.playing.state);
                return false;
            }
        }

        this.refresh.lastRefreshCheck = <Date><any>now; // won't be saved unless use cookies

        let playList = await this.getPlaylist();

        if (playList) {
            Debug.log('refreshPage', playList);
            this.processPlaytestResponse(playList, true);

            return true;
        }

        return false;
    }

    processPlaytestResponse(response: any, refreshMode: boolean) {

        try {
            if (this.options.stream && !response.studies.length && !response.liveVideoCount) {
                Debug.log('Warning: stream not found', this.options.stream);
                Util.showToast('Missing video', true);
                this.showError('Missing video');
                this.loading = false;
                return false;
            }

            if (refreshMode) {
                if (response.hash == this.playList.hash) {
                    // auto play live video - if not already playing
                    this.autoPlayLiveVideoOnLoad();
                    return false;
                }

                //Debug.log('refresh - new hash', response.hash, response);
                this.playList.hash = response.hash;
            }

            if (!response.studies) {
                Debug.error("processPlaytestResponse missing videos", response);
                return false;
            }

            //Debug.log('processPlaytestResponse new playlist', response.hash, response); //, JSON.stringify(response, <any>' ', 3));

            this.addStudyVideoPrivateMembers(response.studies);
            PlayerUtil.addVideoListPrivateMembers(response.liveVideos);

            PlayerUtil.synchronizeStudies(this.study.list, response.studies);
            PlayerUtil.synchronizeVideos(this.live.videos, response.liveVideos);

            this.updateMiniPlayers(this.playing.video);

            if (!this.playing.video || !this.playing.video.isLive) {
                this.selectStudy();
            }

            if (!this.playing.video) {
                if (this.playableMiniVideos.length > 1) {
                    this.showMultiPlayers = true;
                } else {
                    if (this.playing.pendingStream) {
                        this.playing.pendingStream = false;
                        // play first video if options.stream specified - should be only stream in the list
                        if (this.study.list.length > 0 && this.study.list[0].videos.length > 0) {
                            this.playVideo(this.study.list[0].videos[0]);
                        } else if (this.live.videos.length > 0) {
                            this.playVideo(this.live.videos[0]);
                        }
                    }

                    // auto play live video
                    this.autoPlayLiveVideoOnLoad();
                }
            }

            if (!refreshMode)
                this.refreshPage(true);

            return true;
        } catch (e) {
            Debug.error("processPlaytestResponse", e);
            return false;
        }

    }

    updateMiniPlayers(playing: IVideo) {
        if (!this.options.autoPlay) return;

        let notPlaying = [];
        let playable = this.playableMiniVideos;
        if (playable.length > 1) {
            // Update list of videos eligible for mini players on top
            this.live.videos.forEach(video => {
                if (playing && playing.id == video.id)
                    return;

                notPlaying.push(video);
            });
        }

        PlayerUtil.tailSynchronizeVideos(this.miniVideos, notPlaying, 'miniVideos');
    }

    addStudyVideoPrivateMembers(studyList: IStudy[]) {
        studyList.forEach(study => {
            PlayerUtil.addVideoListPrivateMembers(study.videos);
        });
    }

    // Only used for testing purposes
    async checkStream(video, reason: string = null) {
        let asset = this.checkStreamExists(video, reason);
        if (asset) {
            //Debug.log("checkStream valid", video.studio, video.id, video, asset);
            return asset;
        }

        return false;
    }

    autoPlayLiveVideoOnLoad() {
        if (!this.options.autoPlay || this.live.videos.length == 0)
            return;

        if (this.playing.video) {
            if (!this.options.alwaysPlayNewVideo)
                return;

            // skip if playing same video
            if (this.playing.video.id == this.live.videos[0].id)
                return;

            this.stopVideo(this.playing.video);
        }

        Debug.log("autoPlayLiveVideoOnLoad ", this.playList.hash, this.playing.video);

        let video = this.live.videos.find(video => {
            return !video.player;
        });

        // directly play the liveVideo
        if (video) {
            this.playVideo(video);
        }
    }

    async getPlaylist(cached = false) {
        try {
            let model: SUR.PlaylistDto = {
                studio: this.studio.studio,
                hash: this.playList.hash,
                cached: cached,
                fakeNewVideos: this.options.fakeNewVideos,
                fakeLiveVideos: this.options.fakeLiveVideos
            };

            return await PlayerApi.playList(model);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("error", message);
            return false;
        }
    }

    async getStreamUrl(video) {
        try {
            return await PlayerApi.getStreamUrl(video);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("error", message);
            return null;
        }
    }

    async checkStreamExists(video, reason) {
        try {
            return <IVideo><any>await PlayerApi.checkStream(video);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('checkStreamExists', message, video, reason);
            return null;
        }
    }

    // ReSharper disable once InconsistentNaming
    async recreateMP4(video) {
        try {
            return await PlayerApi.recreateMP4(video, false);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("recreateMP4 exception", video.studio, video.id, video, message);
            Util.showToast('Failed to create mp4. ' + message, true);
            return null;
        }
    }

    showInitialSettings() {
        let optionalSettings = this.options.settings || window.sessionStorage.getItem('options.settings') == '1';
        window.sessionStorage.setItem('options.settings', null);

        if (optionalSettings) {
            this.showSettingsWizard('studioSettings', null, 0, true);
            return;
        }

        if (this.options.debugSettings) {
            this.showSettingsWizard(this.options.debugSettings || 'permissions', null, 800, true);
            return;
        }
    }

    showSettingsWizard(name: string, video: IVideo = null, width: number = 0, clearStack = false) {
        let model: IShowSettings = {
            name: name,
            parent: this,
            video: video,
            width: width,
            clearStack: clearStack
        };

        EventBus.showSettingsWizard(model);
    }

    testStreamExists() {
        let video = this.playing.video || this.study.list[0].videos[0];
        this.checkStream(video, 'buffering');
    }

    testDeleteVideo() {
        let video = this.playing.video || this.study.list[0].videos[0];
        this.deleteVideo(video);
    }

    testShowToast(message) {
        Util.showToast(message || 'Missing message');
    }

    checkCanAutoPlay() {
        try {
            canAutoPlay.video().then(({ result, error }) => {
                try {
                    //Debug.log('checkCanAutoPlay', result, Util.dumpJson(error));

                    if (result) {
                        this.canAutoPlay = true;
                    } else {
                        this.canAutoPlay = false;
                        //Debug.log('checkCanAutoPlay muted playback only');
                    }
                } catch (err) {
                    console.error('checkCanAutoPlay', err);
                }
            });
        } catch (err) {
            console.error('checkCanAutoPlay', err);
        }
    }

    async testRefreshPage(fakeLiveVideos = 0, fakeNewVideos = 0) {
        let model: SUR.PlaylistDto = {
            studio: this.studio.studio,
            hash: this.playList.hash,
            cached: true,
            fakeNewVideos: fakeNewVideos,
            fakeLiveVideos: fakeLiveVideos
        };

        let playList = await PlayerApi.playList(model);
        console.log('test', fakeLiveVideos, fakeNewVideos, playList);

        this.processPlaytestResponse(playList, true);
    }
}
